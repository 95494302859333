import { stringObjectDTO } from './dto'
import { ElMessageBox, MessageBoxData } from 'element-plus'
import './util.dto'

export function welcomeTime (): string {
    const time = new Date()
    const hour = time.getHours()
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome (): string {
    const arr = ['选择性投保是很不明智的行为哦', '月度保单记得月底要过来续保哦?', '员工出险后要第一时间登陆系统报案哦', '您的风险意识非常的棒哦', '先出险后投保的骗保行为是违法的哦']
    const index = Math.floor(Math.random() * arr.length)
    return arr[index]
}

/**
 * 时间格式化
 */
export function dateFormat (fmt: string, date: Date): string {
    let ret
    const opt: stringObjectDTO = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
            fmt = fmt.replace(
                ret[1],
                ret[1].length === 1
                    ? opt[k]
                    : opt[k].padStart(ret[1].length, '0')
            )
        }
    }
    return fmt
}

/**
 * 阻塞等待
 */
export function wait (timeout = 1000): Promise<undefined> {
    return new Promise(resolve => {
        setTimeout(resolve, timeout)
    })
}

/**
 * 节流
 */
function Shake () {
    let timer = -1
    return function (obj: ()=>undefined, millisecond = 300) {
        clearTimeout(timer)
        timer = setTimeout(obj, millisecond)
    }
}
export const shake = Shake()

// 生成记录点击时间戳的闭包
export function Anit (): () => boolean {
    let antiTimeStamp = 0
    return () => {
        const timeStamp = new Date().getTime()
        if (antiTimeStamp + 1000 < timeStamp || antiTimeStamp - 1000 > timeStamp) {
            antiTimeStamp = timeStamp
            return true
        }
        return false
    }
}
export const anit = Anit()

// 确认窗
export function confirm (title: string, desc: string): Promise<MessageBoxData> {
    return ElMessageBox.confirm(
        desc,
        title,
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
        }
    )
}

export default {
    confirm
}

export function generateMonthOptions (num:number) {
    const today = new Date()
    const months = [] as string[]

    for (let i = 0; i < num; i++) {
        let year = today.getFullYear()
        let month = today.getMonth() + 1 - i
        // 调整年份和月份
        if (month <= 0) {
            year--
            month += 12
        }
        months.push(`${year}-${month.toString().padStart(2, '0')}`)
    }
    return months
}
