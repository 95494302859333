import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '../store/index'
import user from '@/utils/user'
import Index from '../views/index/index.vue'
import global from '@/utils/global'

const routes: Array<RouteRecordRaw> = [
    {
        name: 'Root',
        path: '/',
        component: Index,
        children: [
            {
                path: '/',
                name: 'Home',
                component: () =>
                    import(
                        /* webpackChunkName: "home" */ '../views/index/home/home.vue'
                    )
            },
            {
                path: '/recharge',
                name: 'Recharge',
                meta: {
                    titles: ['账户充值']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Recharge" */ '../views/index/recharge/recharge.vue'
                    )
            },
            {
                path: '/message',
                name: 'Message',
                meta: {
                    titles: ['消息']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Recharge" */ '../views/index/message/message.vue'
                    )
            },
            {
                path: '/recharge/record',
                name: 'RechargeRecord',
                meta: {
                    titles: ['充值记录']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "RechargeRecord" */ '../views/index/recharge-record/recharge-record.vue'
                    )
            },
            {
                path: '/invoice/record',
                name: 'InvoiceRecord',
                meta: {
                    titles: ['发票记录']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "InvoiceRecord" */ '../views/index/invoice-record/invoice-record.vue'
                    )
            },
            {
                path: '/invoice/bill',
                name: 'InvoiceBill',
                meta: {
                    titles: ['期初费用']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "InvoiceRecord" */ '../views/index/invoice-bill/invoice-bill.vue'
                    )
            },
            {
                path: '/invoice/apply',
                name: 'InvoiceApply',
                meta: {
                    titles: ['发票记录', '申请发票']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "InvoiceApply" */ '../views/index/invoice-apply/invoice-apply.vue'
                    )
            },
            {
                path: '/workers',
                name: 'Workers',
                meta: {
                    titles: ['员工查询']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Workers" */ '../views/index/workers/workers.vue'
                    )
            },
            {
                path: '/dispatch',
                name: 'Dispatch',
                meta: {
                    titles: ['被派遣单位']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Dispatch" */ '../views/index/dispatch/dispatch.vue'
                    )
            },
            {
                path: '/dispatch/add',
                name: 'DispatchAdd',
                meta: {
                    titles: ['被派遣单位', '添加被派遣单位']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "DispatchAdd" */ '../views/index/dispatch-add/dispatch-add.vue'
                    )
            },
            {
                path: '/insurance/plan',
                name: 'InsurancePlan',
                meta: {
                    titles: ['保险方案']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "InsurancePlan" */ '../views/index/insurance-plan/insurance-plan.vue'
                    )
            },
            {
                path: '/insurance/new',
                name: 'InsuranceNew',
                meta: {
                    titles: ['保险方案', '开通新方案']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "InsuranceNew" */ '../views/index/insurance-new/insurance-new.vue'
                    )
            },
            {
                path: '/insurance/detail/:id',
                name: 'InsuranceDetail',
                meta: {
                    titles: ['保险方案', '保险详情']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "InsuranceDetail" */ '../views/index/insurance-detail/insurance-detail.vue'
                    )
            },
            {
                path: '/insurance/order/:id',
                name: 'InsuranceOrder',
                meta: {
                    titles: ['加减保记录', '详情']
                },
                component: () =>
                    import(
                        '../views/index/insurance-order/insurance-order.vue'
                    )
            },
            {
                path: '/report',
                name: 'Report',
                meta: {
                    titles: ['报案理赔']
                    // keepAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Report" */ '../views/index/report/report.vue'
                    )
            },
            {
                path: '/report/add',
                name: 'ReportAdd',
                meta: {
                    titles: ['报案理赔', '新增理赔']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "ReportAdd" */ '../views/index/report-add/report-add.vue'
                    )
            },
            {
                path: '/report/detail/:id',
                name: 'ReportDetail',
                meta: {
                    titles: ['报案理赔', '理赔详情']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "ReportDetail" */ '../views/index/report-detail/report-detail.vue'
                    )
            },
            {
                path: '/order-pdf',
                name: 'OrderPdf',
                meta: {
                    titles: ['在保证明']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "OrderPdf" */ '../views/index/order-pdf/order-pdf.vue'
                    )
            },
            {
                path: '/order-pdf/add-order-pdf',
                name: 'AddOrderPdf',
                meta: {
                    titles: ['新增在保证明']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "OrderPdf" */ '../views/index/order-pdf/add-order-pdf.vue'
                    )
            },
            {
                path: '/insurance/addsubtract/:type/:id',
                name: 'InsuranceAddSubtract',
                meta: {
                    titles: ['保险方案', '保险详情', '加减保']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "InsuranceAddSubtract" */ '../views/index/Insurance-add-subtract/Insurance-add-subtract.vue'
                    )
            },
            {
                path: '/team',
                name: 'Team',
                meta: {
                    titles: ['团队管理']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Team" */ '../views/index/team/team.vue'
                    )
            },
            {
                path: '/success',
                name: 'Success',
                meta: {
                    titles: ['提交成功']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Success" */ '../views/index/success/success.vue'
                    )
            },
            {
                path: '/paysuccess',
                name: 'PaySuccess',
                meta: {
                    titles: ['提交成功']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "PaySuccess" */ '../views/index/success/success.vue'
                    )
            }
        ]
    },
    {
        name: 'Login',
        path: '/login',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/login.vue')
    }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    // 控制菜单栏是否显示
    store.commit('setTitles', to.meta.titles)
    // 没有token时自动跳转到登录界面
    if (to.name === 'Login' && to.query.token) {
        localStorage.tokenC = to.query.token
        global.token = localStorage.tokenC
        user.getUserInfo()
        next('/')
    } else if (!global.token && !localStorage.tokenC && to.name !== 'Login') {
        next('/login')
    } else {
        next()
    }
})
export default router
